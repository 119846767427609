@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

.App {
  text-align: center;
  background-color: rgb(226, 55, 68);
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: Nunito, Helvetica, sans-serif;
}

.App-logo {
  pointer-events: none;
  width: 200px;
  margin: 32px 0;
}

input {
  padding: 16px;
  border-radius: 6px;
  border: 1px solid rgb(207, 207, 207);
  margin: 0 16px;
  font-size: 1.6rem;
}

input[type="submit"] {
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  cursor: pointer;
  color: rgb(105, 105, 105);
}

form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.title,
.description {
  color: white;
  font-weight: normal;
  margin: 0;
}

.bold {
  font-weight: bold;
}
